var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer kt-grid__item bgi-position-center-center bgi-size-cover bgi-no-cover",staticStyle:{"background-image":"url('media/bg/bg-mdtaf.png')"},attrs:{"id":"kt_footer"}},[_c('div',{staticClass:"container py-lg-18 py-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 my-lg-0 my-5"},[_c('h4',{staticClass:"text-white pb-3"},[_vm._v(_vm._s(_vm.brand))]),_c('p',{staticClass:"m-0 text-white opacity-45",domProps:{"innerHTML":_vm._s(
            _vm.$t(
              'Platform for buying and selling digital services.'
            )
          )}})]),_c('div',{staticClass:"col-lg-4 my-lg-0 my-5"},[_c('h4',{staticClass:"text-white pb-3"},[_vm._v(" "+_vm._s(_vm.$t("Quick Links"))+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"d-flex flex-column mr-18"},[_c('router-link',{attrs:{"to":"/home"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"text-white opacity-55 text-hover-primary",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("Home"))+" ")])]}}])})],1),_c('div',{staticClass:"d-flex flex-column"})])]),_c('div',{staticClass:"col-lg-4 my-lg-0 my-5"},[_c('h4',{staticClass:"text-white pb-3"},[_vm._v(" "+_vm._s(_vm.$t("Get In Touch"))+" ")]),_c('form',{staticClass:"rounded",staticStyle:{"background-color":"rgba(0,0,0,.2)"}},[_c('div',{staticClass:"input-group p-2 align-items-center"},[_c('input',{staticClass:"form-control rounded-right border-0 bg-transparent text-white opacity-80",attrs:{"type":"text","placeholder":"Enter Your Email"}}),_c('div',{staticClass:"input-group-append p-0 mr-1"},[_c('button',{staticClass:"btn btn-fh btn-sm btn-primary px-6 rounded-left",staticStyle:{"z-index":"1"},attrs:{"type":"button"}},[_vm._v(" "+_vm._s(_vm.$t("Join us"))+" ")])])])])])])]),_c('div',{staticClass:"separator separator-solid opacity-7"}),_c('div',{staticClass:"container py-8"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between flex-lg-row flex-column"},[_c('div',{staticClass:"d-flex align-items-center order-lg-1 order-2"},[_c('img',{staticClass:"logo-sticky max-h-35px",attrs:{"alt":"Logo","src":"media/logos/logo.png"}}),_c('span',{staticClass:"text-muted font-weight-bold mx-2"},[_vm._v("2020 ©")]),_c('a',{staticClass:"text-primary text-hover-primary",attrs:{"href":"https://gitlab.com/longbowou"}},[_vm._v(" "+_vm._s(_vm.brand)+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }